.MyAPIs {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
}

.MyAPIs h1 {
    color: #333;
}
.MyAPIs .endpoint {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}
.MyAPIs .endpoint h2 {
    margin-top: 0;
}
.MyAPIs .endpoint table {
    width: 100%;
    border-collapse: collapse;
}
.MyAPIs .endpoint table, .endpoint th, .endpoint td {
    border: 1px solid #ddd;
}
.MyAPIs .endpoint th, .endpoint td {
    padding: 8px;
    text-align: left;
}
.MyAPIs .endpoint th {
    background-color: #f2f2f2;
}
.MyAPIs .code {
    font-family: monospace;
    color: #c7254e;
    background-color: #f9f2f4;
    padding: 2px 4px;
    border-radius: 4px;
}