.GenerateImage {
	display: flex;
    flex-direction: column;
}
.GenerateImage *{
    width: 50%;
    margin-bottom: 20px;
}
.GenerateImage Button {
    align-self: center;

}
@media only screen and (max-width: 768px) {
    .GenerateImage *{
        width: 80%;
    }
}