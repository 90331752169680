.AlbumCard {
	width: 28%;
    height:300px;
    background-image: url('/public/folder.png');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.AlbumCard .name{
    margin-top: 50px;
}
