.Docs h2 {
	background-color: #5063af;
    padding: 5px;
}

.Docs a{
    border: solid 2px black;
    border-radius: 10px;
    background-color: #6dd9f3;
    padding: 10px;
    margin: 10px;
    display: inline-block;
    text-decoration: none;
}
