.Header{
	display: flex;
    justify-content: space-around;
    background-color: #4d93f1;
}

.Header h1{
    text-align: center;
}

.Header menu{
    display: flex;
    justify-content: space-around;
    padding-left: 0;
}

.Header img{
    height: 72px;
    margin-top: 10px;
}