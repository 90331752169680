.AlbumDetails .container{
    display: flex;
    transform: translatez(0);
}

.AlbumDetails Dialog{
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
